import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';

function ControlledCarousel() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel interval={3000} activeIndex={index} onSelect={handleSelect} style={{top:0}}>
     <Carousel.Item style={{ width: '100%', height: '650px', top:0 }}>
      <div className="container">
          <div className="row">
            <div className='col-sm-12' style={{textAlign:'justify'}}>
              <div style={{justifyContent:'center', alignContent:'center'}}>  
              <img src={require("./assets/applelogo.png")} alt="apple logo" style={{width:'60px'}} className="img-fluid" fluid="true" /> <span style={{color:'#fff'}}>Apple Inc</span> </div>
            </div>
            </div>
            <div className="row">
            <div className="col-sm-12 d-flex justify-content-center align-items-center">
                  <img
                    src={require("./assets/IPhone15s-clean.png")}
                    alt="Apple IPhone"
                    style={{                     
                      maxWidth: '100%', // Ensure the image doesn't exceed its container's width
                      height: 'auto', // Maintain the image's aspect ratio
                    }}
                    className="img-fluid animate__animated animate__bounce animate__delay-2s animate__repeat-2"
                  />
                </div>
            </div>
            <div className="row">           
            <div className="col-sm-12" style={{paddingTop:20}}>             
             <h3 style={{color: '#fff', textAlign:'center', fontFamily:'Roboto'}}>We are your best plug for all original Apple IPHONES. All latest IPhones are in stock!</h3>
            </div>
            </div>
        </div>
      </Carousel.Item>     
      <Carousel.Item style={{ width: '100%', height: '650px', top:0 }}>
      <div className="container">
          <div className="row">
            <div className='col-sm-12' style={{textAlign:'justify'}}>
              <div style={{justifyContent:'center', alignContent:'center'}}>  
              <img src={require("./assets/samsunglogo-clean.png")} alt="Samsung logo" style={{width:'60px', backgroundColor:'#fff'}} fluid="true" /> <span style={{color:'#fff'}}></span> </div>
            </div>
            </div>
            <div className="row">
            <div className="col-sm-12 d-flex justify-content-center align-items-center" style={{paddingTop:100}}>
                  <img
                    src={require("./assets/samsung-fold-clean.png")} alt="Samsung Fold 5"                   
                    style={{                     
                      maxWidth: '100%', // Ensure the image doesn't exceed its container's width
                      height: 'auto', // Maintain the image's aspect ratio
                    }}
                    className="img-fluid animate__animated animate__bounce animate__delay-2s animate__repeat-2"
                  />
                </div>
          
            </div>
            <div className="row">           
            <div className="col-sm-12" style={{marginTop:50}}>             
             <h3 style={{color: '#fff', textAlign:'center', fontFamily:'Roboto'}}>We are your best plug for all original Samsung S23 and S23Ultra. All latest Samsung phones are in stock!</h3>
            </div>
            </div>
        </div>
      </Carousel.Item>
      <Carousel.Item style={{ width: '100%', height: '650px', top:0 }}>
      <div className="container">
          <div className="row">
            <div className='col-sm-12' style={{textAlign:'justify'}}>
              <div style={{justifyContent:'center', alignContent:'center'}}>  
              <img src={require("./assets/samsunglogo-clean.png")} alt="Samsung logo" style={{width:'60px', backgroundColor:'#fff'}} fluid="true" /></div>
            </div>
            </div>
            <div className="row">            
            <div className="col-sm-12 d-flex justify-content-center align-items-center" style={{paddingTop:50}}>
                  <img
                    src={require("./assets/samsungcombo-clean.png")} alt="Samsung fold and flip"                   
                    style={{                     
                      maxWidth: '100%', // Ensure the image doesn't exceed its container's width
                      height: 'auto', // Maintain the image's aspect ratio
                    }}
                    className="img-fluid animate__animated animate__bounce animate__delay-2s animate__repeat-2"
                  />
                </div>
            </div>  
            <div className="row">           
            <div className="col-sm-12" style={{paddingTop:50}}>             
             <h3 style={{color: '#fff', textAlign:'center', fontFamily:'Roboto'}}>We are your best plug for all original Samsung Fold and Flip. All latest Samsung phones are in stock!</h3>
            </div>
            </div>
        </div>
      </Carousel.Item>
      <Carousel.Item style={{ width: '100%', height: '650px', top:0 }}>
      <div className="container">
          <div className="row">
            <div className='col-sm-12' style={{textAlign:'justify'}}>
              <div style={{justifyContent:'center', alignContent:'center'}}>  
              <img src={require("./assets/samsunglogo-clean.png")} alt="Samsung logo" style={{width:'60px', backgroundColor:'#fff'}} fluid="true" /></div>
            </div>
            </div>
            <div className="row">
            <div className="col-sm-12 d-flex justify-content-center align-items-center" style={{paddingTop:50}}>
                  <img
                    src={require("./assets/samgalaxys23-clean.png")} alt="Samsung S23 Ultra"                  
                    style={{                     
                      maxWidth: '100%', // Ensure the image doesn't exceed its container's width
                      height: 'auto', // Maintain the image's aspect ratio
                    }}
                    className="img-fluid animate__animated animate__bounce animate__delay-2s animate__repeat-2"
                  />
                </div>        
            </div>
            <div className="row">           
            <div className="col-sm-12" style={{paddingTop:50}}>             
             <h3 style={{color: '#fff', textAlign:'center', fontFamily:'Roboto'}}>We are your best plug for all original Samsung S23 and S23Ultra. All latest Samsung phones are in stock!</h3>
            </div>
            </div>
        </div>
      </Carousel.Item>
      <Carousel.Item style={{ width: '100%', height: '650px', top:0 }}>
      <div className="container">
          <div className="row">
            <div className='col-sm-12' style={{textAlign:'justify'}}>
              <div style={{justifyContent:'center', alignContent:'center'}}>  
              <img src={require("./assets/samsunglogo-clean.png")} alt="Samsung logo" style={{width:'60px', backgroundColor:'#fff'}} fluid="true" /></div>
            </div>
            </div>
            <div className="row">
            <div className="col-sm-12 d-flex justify-content-center align-items-center" style={{paddingTop:20}}>
                  <img
                    src={require("./assets/samsungwatchseries.png")} alt="samsung watches"                   
                    style={{                     
                      maxWidth: '100%', // Ensure the image doesn't exceed its container's width
                      height: 'auto', // Maintain the image's aspect ratio
                    }}
                    className="img-fluid animate__animated animate__bounce animate__delay-2s animate__repeat-2"
                  />
                </div>           
            </div>
            <div className="row">           
            <div className="col-sm-12" style={{paddingTop:50}}>             
             <h3 style={{color: '#fff', textAlign:'center', fontFamily:'Roboto'}}>We are your best plug for all original Samsung Smart Watch. All latest Samsung Watches are in stock!</h3>
            </div>
            </div>
        </div>
      </Carousel.Item>
      <Carousel.Item style={{ width: '100%', height: '650px', top:0 }}>
      <div className="container">
          <div className="row">
            <div className='col-sm-12' style={{textAlign:'justify'}}>
              <div style={{justifyContent:'center', alignContent:'center'}}>  
              <img src={require("./assets/applelogo.png")} alt="apple logo" style={{width:'60px'}} fluid="true" /> <span style={{color:'#fff'}}>Apple Inc</span> </div>
            </div>
            </div>
            <div className="row">
            <div className="col-sm-12 d-flex justify-content-center align-items-center" style={{paddingTop:10}}>
                  <img
                    src={require("./assets/applewatchs9-clean.png")} alt="apple watch"                  
                    style={{                     
                      maxWidth: '100%', // Ensure the image doesn't exceed its container's width
                      height: 'auto', // Maintain the image's aspect ratio
                    }}
                    className="img-fluid animate__animated animate__bounce animate__delay-2s animate__repeat-2"
                  />
                </div> 
            </div>
            <div className="row">           
            <div className="col-sm-12" style={{paddingTop:50}}>             
             <h3 style={{color: '#fff', textAlign:'center', fontFamily:'Roboto'}}>We are your best plug for all original Apple WATCH. All Models are in stock!</h3>
            </div>
            </div>
        </div>
      </Carousel.Item>
      <Carousel.Item style={{ width: '100%', height: '650px', top:0 }}>
      <div className="container">
          <div className="row">
            <div className='col-sm-12' style={{textAlign:'justify'}}>
              <div style={{justifyContent:'center', alignContent:'center'}}>  
              <img src={require("./assets/applelogo.png")} alt="apple logo" style={{width:'60px'}} fluid="true" /> <span style={{color:'#fff'}}>Apple Inc</span> </div>
            </div>
            </div>
            <div className="row">
            <div className="col-sm-12 d-flex justify-content-center align-items-center" style={{paddingTop:50}}>
                  <img
                    src={require("./assets/appleairpod-clean.png")} alt="apple airpods"                 
                    style={{                     
                      maxWidth: '100%', // Ensure the image doesn't exceed its container's width
                      height: 'auto', // Maintain the image's aspect ratio
                    }}
                    className="img-fluid animate__animated animate__bounce animate__delay-2s animate__repeat-2"
                  />
                </div> 
            </div>
            <div className="row">           
            <div className="col-sm-12" style={{paddingTop:70}}>             
             <h3 style={{color: '#fff', textAlign:'center', fontFamily:'Roboto'}}>We are your best plug for all original Apple AIRPODS and ACCESSORIES. All Models are in stock!</h3>
            </div>
            </div>
        </div>
      </Carousel.Item>
    </Carousel>
  );
}

export default ControlledCarousel;