import React, { Component } from 'react'

export default class Mainbody extends Component {
  render() {
    return (
        <div>
      <div className='container-fluid' style={{fontFamily:'Roboto', backgroundColor:'#fff'}}>
      <div className='row'>
            <div className='col-sm-12' style={{padding:20, textAlign:'center'}}>               
               <section id='OurAdvantage' style={{textAlign:'center', fontSize:28, fontWeight:700}}> 
               WHY US - OUR ADVANTAGE
               </section>
            </div>
        </div>
        <div className='row' style={{padding:5, borderBottomWidth:0, borderBottomStyle:'solid', borderBottomColor:'#ccc'}}>
            <div className='col-sm-12'>           
               <div style={{fontSize:20, padding:10,textAlign:'justify'}}>
                 Welcome to <span style={{fontWeight:700}}>Nijatech Nigeria Limited </span> – Your one-stop center for quality gadget! Why settle for ordinary when you can experience the extraordinary? <span style={{color:'blue', fontWeight:700}}> Nijatech </span> is not just a company; it's your tech haven, where authenticity meets excellence!
               <br /> 
               <p>Thinking of getting a new or used Apple or Samsung Gadget, the facts about us, listed below, will surely convince you to patronise us for your own peace of mind: </p>
               </div>
            </div>
        </div>
        <div className='row' style={{borderBottomWidth:0, marginTop:2, borderBottomStyle:'solid', borderBottomColor:'#ccc'}}>
            <div className='col-sm-4'>
           <div style={{justifyContent:'center', backgroundColor:'#fff', padding:55}}>
            <img src= {require("./assets/achievement.png")} alt="Nijatech Origal gadgets" className='img-fluid'  style={{                     
                      maxWidth: '100%', // Ensure the image doesn't exceed its container's width
                      height: 'auto', // Maintain the image's aspect ratio
                    }}/>  </div> 
            </div>
            <div className='col-sm-8' style={{padding:20}}> 
            <h3 style={{fontWeight:900, textAlign:'center'}}>✨ Originality Guaranteed </h3>
              <hr />
              <div style={{padding:20, borderWidth:1, borderColor:'#ddd',width:'95%', justifyContent:'center'}}> 
               
             <div style={{textAlign:'justify', fontFamily:'Roboto', marginTop:5, padding:10,fontSize:20}}>              
At Nijatech, we take pride in being the major importer of smartphones and gadgets that redefine perfection. Every phone, whether brand new or Used is gently pre-loved, is meticulously sourced, authenticated, and offered to you in its original, pristine condition. Say goodbye to compromises – say hello to authenticity!
         <br /><br />  <p> <b>Originality is our Signature: We guarantee authenticity in every gadget</b></p>  
            </div>
              </div> 
            </div>
        </div>
        <div className='row' style={{marginTop:5}}>    
        <div className='col-sm-8' style={{padding:20}}> 
            <h3 style={{fontWeight:900, textAlign:'center'}}>🔧 Expert Repairs, Exceptional Care</h3>
              <hr />
              <div style={{padding:20, borderWidth:1, borderColor:'#ddd',width:'95%', justifyContent:'center'}}>   
             <div style={{textAlign:'justify', fontFamily:'Roboto', marginTop:5, padding:10,fontSize:18}}>              
Encountered a bump on the tech road? Fear not! Our squad of qualified engineers is here to bring your gadgets back to life. Nijatech doesn't just sell; we nurture and care for your devices. Trust us to handle your repairs with precision, ensuring your gadgets are in top-notch condition, just like the day you unboxed them.
             <p>Expert Repairs, Prompt Service: Our engineers are at your service, ensuring your devices stay flawless.</p> </div>
              </div> 
            </div>   
              <div className='col-sm-4'>
              <div style={{justifyContent:'center', backgroundColor:'#fff', padding:30}}>
            <img src= {require("./assets/phonerepair2.jpg")} alt="Nijatech Original gadgets" className='img-fluid' style={{width:'100%'}}/>  </div> 
            </div>
           
        </div>
        <div className='row' style={{marginTop:5}}>  
        <div className='col-sm-4'>
        <div style={{justifyContent:'center', backgroundColor:'#ddd', padding:30}}>
            <img src= {require("./assets/smartphoneswap.jpg")} alt="Nijatech Origal gadgets" className='img-fluid' style={{width:'100%'}}/>  </div> 
            </div>         
            <div className='col-sm-8' style={{padding:20}}> 
              <div style={{padding:20, borderWidth:1, borderColor:'#ddd',width:'95%',  justifyContent:'center'}}> 
              <h3 style={{fontWeight:900, textAlign:'center'}}>** 💸 Sell or Swap with Ease</h3>
              <hr />
              <div style={{textAlign:'justify', fontFamily:'Roboto', padding:10, fontSize:18}}>             
Ready for an upgrade? At Nijatech, we understand that tech evolves, and so do your needs. That's why we offer you the freedom to sell or swap your current devices hassle-free. Your old gadgets not only find a new home but also contribute to a sustainable tech ecosystem.
              <p>Upgrade with Ease: Sell or swap your gadgets effortlessly, embracing the latest in tech.</p> </div>
              </div>                
            </div>           
        </div>    
        <div className='row' style={{marginTop:5, textAlign:'justify',fontSize:18, padding:20 }}>
            <div className='col-sm-12'>
            Your tech journey deserves the best, and Nijatech Nigeria Ltd is here to deliver nothing short of excellence. Step into a world where innovation meets trust, where gadgets tell a story of quality, and where every click is a seamless experience.
             Ready to elevate your tech game? Explore Nijatech today – Because Excellence Is Non-Negotiable! 
            </div>
            </div>          
        <div className='row' style={{fontFamily:'Roboto', marginTop:2, backgroundColor:'#041E42'}}>
                <div className='col-sm-7'>                
                <div style={{padding:20, borderWidth:1, borderColor:'#ddd',width:'95%', justifyContent:'center'}}> 
                <h4 style={{fontWeight:400, color:'#fff', textAlign:'justify'}}><span style={{fontWeight:700}}>**</span>  BUY & SWAP SERVICE TERMS AND CONDITION </h4>
              <hr style={{borderColor:'#fff', borderStyle:'solid'}}/>
              <div style={{textAlign:'justify', color:'#fff'}}>
                Customer who wish swap their existing phone or sell their phones to us should please read and understand the points outlined below:
                <ul>
                    <li style={{padding:5}}>Buy and Swap services are only available at our office in Computer Village, Ikeja </li>
                    <li style={{padding:5}}>You must provide proof of ownership of the phone </li>
                    <li style={{padding:5}}>Your phone must be in good working working</li>
                    <li style={{padding:5}}>Take a picture with your phone in our office before the transaction - for our record</li>                   
                </ul>
                PLEASE NOTE:
                <h4 style={{fontWeight:700}}> WE DO NOT ACCEPT OR TRADE IN STOLEN PHONES!</h4>
              </div>
              </div>              
            </div>
            <div className='col-sm-5' style={{textAlign:'center'}}> 
              <div style={{padding:20, borderWidth:1, borderColor:'#ddd',width:'95%',  justifyContent:'center'}}> 
              <section id="Contactus">
              <h4 style={{fontWeight:400, color:'#fff'}}> CONTACT US </h4>
              <hr style={{borderColor:'#fff', borderStyle:'solid'}}/>  
              <div>
                <div style={{color:'#fff', fontWeight:600}}> 
                <h5 style={{fontWeight:600,fontStyle:'bold'}}> Sales Hotline: </h5>
                <div style={{fontWeight:600,fontStyle:'bold',fontSize:22}}> Call: 0903 913 7405 | Chat: 0803 331 5354 </div>
                <h5 style={{fontWeight:600,fontStyle:'bold'}}>  </h5>
                </div>
                <hr style={{borderColor:'#fff', borderStyle:'solid'}}/>               
                <div style={{color:'#fff', fontWeight:600}}> 
                Email: hello@Nijatech.ng
                </div>
                <hr style={{borderColor:'#fff', borderStyle:'solid'}}/>
                <div style={{color:'#fff', fontWeight:600}}> 
                    Office Address: 1, Otigba Street (3rd floor), beside Fidelity Bank, Computer Village (Opposite Awolowo House), Ikeja, Lagos. 
                </div>
                <hr style={{borderColor:'#fff', borderStyle:'solid'}}/>
                <div style={{color:'#fff', fontWeight:600}}> 
                    For Complaints: +234 (0)802 654 4943
                </div>              
              </div>   
              </section>  
              </div>                
            </div>  
            </div>
            <div className='row' style={{fontFamily:'Roboto',padding:20, borderTopWidth:1, borderTopColor:'#fff', borderTopStyle:'solid', backgroundColor:'#041E42',}}>
            <div className='col-sm-12' style={{textAlign:'center'}}>
                <h6 style={{color:'#fff'}}> Nijatech Nigeria Limited &copy; 2023. All rights reserved</h6>
                </div>
            </div>
        </div> 
      
   
    </div>
    )
  }
}
