import React, { Component } from 'react'

export default class AboutBody extends Component {
  render() {
    return (
        <div style={{fontFamily:'Roboto', backgroundColor:'#fff', height:650, width:'100%', textAlign:'justify', padding:25}}>
        <h3 style={{fontWeight:700, margin:20}}>ABOUT US - WHO WE ARE</h3>
        <div style={{paddingLeft:50, paddingRight:50}}>
        <p> Welcome to Nijatech Nigeria Limited, your premier destination for cutting-edge technology and unparalleled service. As a proudly registered entity with the Corporate Affairs Commission (RC 1642142), we stand at the forefront of Nigeria's tech landscape, driven by a passion for innovation, authenticity, and customer satisfaction.</p>
 
 <p>At Nijatech, we specialize in importing and distributing original Samsung and Apple gadgets, ensuring that our customers have access to the latest and most reliable devices the technology world has to offer. Our commitment to authenticity is embedded in our ethos, and each product we deliver is a testament to our dedication to quality and excellence.</p>
 
 <p>Beyond our extensive range of gadgets, we boast a state-of-the-art repair center, equipped with the latest tools and staffed by skilled technicians. Our repair services extend a lifeline to your devices, ensuring they are restored to optimal functionality with precision and care. Trust us with your gadgets, and we'll breathe new life into your digital companions.</p>
 
 <p>Recognizing the dynamic nature of the tech industry, we offer a unique service that goes beyond sales and repairs. Customers have the opportunity to sell their old phones or swap them for the latest models, providing a seamless and sustainable solution for upgrading to the next level of innovation. We believe in fostering a circular economy where every device finds a new purpose and contributes to a more sustainable future.</p>
 
 <p>Our customer-centric approach sets us apart. From the moment you explore our curated selection of gadgets to the swift and reliable repairs we provide, your satisfaction is our top priority. Nijatech is more than a company; it's a community of tech enthusiasts dedicated to elevating your digital experience.</p>
 
 <p>Embark on a journey with Nijatech Nigeria Limited, where authenticity meets innovation, and where your tech aspirations find a home. Explore our range of original Samsung and Apple gadgets, experience the excellence of our repair services, and discover the freedom to upgrade with our phone-selling and swapping options. Welcome to a world where technology meets trust, and where Nijatech is not just a company – it's a commitment to your tech journey.</p>
       </div>
       </div>
    )
  }
}
