// App.jsx

import React from 'react';
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import Home from './Component/Home';
import About from './Component/About';
import OurServices from './Component/OurServices';
import Nav from './Component/NavigationControl'

const App = () => {
  return (
    <div className='App'>
            <Nav/>
            <Routes>
                 <Route path="/" element={<Home />} />
                 <Route path="/about" element={<About />} />
                 <Route path="/services" element={<OurServices />} />
            </Routes>
    </div>
  )
};

export default App;
