import React, { Component } from 'react'
import Carousel from './ControlledCarousel';
import Mainbody from './Mainbody';
import Footer from './Footer';

import Navigation from './NavigationControl';
import Nav from './NavigationBar';
export default class Home extends Component {
  render() {
    return (
      <div style={{padding:0, margin:0, backgroundColor:'#0a2351', display: 'flex',flexDirection: 'column', justifyContent:'center',minHeight: '100vh'}}>
        <Carousel />
        <Mainbody />
      </div>
    )
  }
}
