import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';

const NavigationControl = () => {
  return (
     <>
      {[false].map((expand,index) => (
        <Navbar key={`${expand}-${index}`} expand={expand} className="bg-body-tertiary mb-3">
          <Container fluid>
            <Navbar.Brand href="#"> <img src= {require("./assets/nijatechwebLogo.png")} alt="Nijatech Logo" className='img-fluid' style={{width:50}}/> <span style={{color:'#1560bd', fontWeight:900, fontSize:25, fontFamily:'Rethink Sans'}}> NIJATECH </span></Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Menu
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link href="/"><img src= {require("./assets/home.png")} alt="home menu icon" className='img-fluid' style={{width:40, backgroundColor:'#1560bd', padding:10}} /> Home</Nav.Link>
                  <Nav.Link href="/about"><img src= {require("./assets/question-mark.png")} alt="home menu icon" className='img-fluid' style={{width:40, backgroundColor:'#1560bd', padding:10}} /> About Us </Nav.Link>
                  <Nav.Link href="/services"> <img src= {require("./assets/ourservice.png")} alt="home menu icon" className='img-fluid' style={{width:40, backgroundColor:'#1560bd', padding:5}} /> Our Services </Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default NavigationControl;