import React, { Component } from 'react'
import Navigation from './NavigationControl';

export default class OurServices extends Component {
  render() {
    return (
        <div style={{padding:0, margin:0, backgroundColor:'#0a2351', display: 'flex',flexDirection: 'column',minHeight: '100vh'}}>
           
        <div className='container-fluid' style={{fontFamily:'Roboto', backgroundColor:'#fff'}}>
        <div className='row'>
            <div className='col-sm-12' style={{padding:5,textAlign:'center'}}> 
               <section id='Ourservices' style={{textAlign:'center'}}> <h3>WHAT WE DO - OUR SERVICES</h3></section>
            </div>
        </div>
        <hr />
        <div className='row' style={{marginTop:10, textAlign:'center'}}>
            <div className='col-sm-6' style={{padding:1}}> 
              <div style={{padding:20, borderWidth:1, borderColor:'#ddd',width:'95%', justifyContent:'center'}}> 
                    <h3 style={{fontWeight:700}}>WE SELL </h3>
                <hr />
                    <img src= {require("./assets/phonesales.jpeg")} alt="Nijatech Logo" className='img-fluid' style={{width:'25%'}}/>  
                    <div style={{textAlign:'justify', fontFamily:'Roboto', marginTop:5, padding:10,fontSize:18}}>
                        In the dynamic world of technology, where innovation and reliability converge, our company stands as a beacon of trust and excellence. In a market flooded with replicas and imitations, we pride ourselves on being a trusted source for original Samsung and Apple gadgets. Our commitment to transparency extends beyond our product offerings; it permeates every aspect of our customer service. 
                    </div>
              </div> 
            </div>
            <div className='col-sm-6' style={{padding:1}}> 
              <div style={{padding:20, borderWidth:1, borderColor:'#ddd',width:'95%', justifyContent:'center'}}> 
              <h3 style={{fontWeight:700}}>** WE BUY</h3>
              <hr />
              <img src= {require("./assets/phonebuy.png")} alt="Nijatech Logo" className='img-fluid' style={{width:'25%'}}/>  
              <div style={{textAlign:'justify', fontFamily:'Roboto', marginTop:5, padding:10,fontSize:18}}>
                Embark on a journey of technological renewal with us, your trusted destination for selling your pre-loved Samsung and Apple gadgets. That's why we take pride in providing a trustworthy solution for selling your used gadgets. Our experienced team meticulously assesses each device, ensuring that it meets our stringent standards for functionality and authenticity</div>
              </div> 
             
            </div>
           
        </div>
        <div className='row' style={{marginTop:10, textAlign:'center'}}>
            <div className='col-sm-6' style={{padding:20}}>                
                <div style={{padding:20, borderWidth:1, borderColor:'#ddd',width:'95%', justifyContent:'center'}}> 
                <h3 style={{fontWeight:700}}>WE REPAIR</h3>
              <hr />
              <img src= {require("./assets/phonerepairservice.jpeg")} alt="Nijatech Logo" className='img-fluid' style={{width:'35%'}}/>  
              <div style={{textAlign:'justify', fontFamily:'Roboto', padding:10, fontSize:18}}>
              Picture this: a shattered screen, a malfunctioning battery, or a stubbornly unresponsive device – challenges that seem insurmountable until they meet the hands of our skilled technicians. Your phone isn't just a gadget; it's an extension of you, holding the keys to your memories, your connections, and your daily rhythms. Step into our repair haven, where cutting-edge technology meets seasoned expertise. Our team of skilled technicians, armed with a wealth of experience, approaches every repair with precision and care.
              </div>
              </div>              
            </div>
            <div className='col-sm-6' style={{padding:20}}> 
              <div style={{padding:20, borderWidth:1, borderColor:'#ddd',width:'95%', justifyContent:'center'}}> 
              <h3 style={{fontWeight:700}}>** WE SWAP </h3>
              <hr />
              <img src= {require("./assets/phoneSwap.jpg")} alt="Nijatech Logo" className='img-fluid' style={{width:'35%'}}/>  
              <div style={{textAlign:'justify', fontFamily:'Roboto', padding:10, fontSize:18}}>
                Welcome to a unique realm where pre-loved phones find new homes and fresh stories unfold. Our phone swap service is not just a marketplace; Open to the public but with an exclusive embrace for those who've chosen our devices, we present an unparalleled journey of technological discovery, sustainability, and community.  Our marketplace welcomes devices meticulously curated to ensure that each phone meets our stringent standards of functionality and authenticity.
               </div>
              </div>                
            </div>           
        </div>     
        <div className='row' style={{backgroundColor:'#041E42', marginTop:20, textAlign:'center'}}>
                <div className='col-sm-7'>                
                <div style={{padding:20, borderWidth:1, borderColor:'#ddd',width:'95%', justifyContent:'center'}}> 
                <h4 style={{fontWeight:400, color:'#fff', textAlign:'justify'}}><span style={{fontWeight:700}}>**</span>  BUY & SWAP SERVICE TERMS AND CONDITION </h4>
              <hr style={{borderColor:'#fff', borderStyle:'solid'}}/>
              <div style={{textAlign:'justify', color:'#fff'}}>
                Customer who wish swap their existing phone or sell their phones to us should be please read and understand the points outlined below:
                <ul>
                    <li style={{padding:5}}>Buy and Swap services are only available at our office in Computer Village, Ikeja </li>
                    <li style={{padding:5}}>You must provide proof of ownership of the phone </li>
                    <li style={{padding:5}}>Your phone must be in good working working</li>
                    <li style={{padding:5}}>Take a picture with your phone in our office before the transaction - for our record</li>                   
                </ul>
                PLEASE NOTE:
                <h4 style={{fontWeight:700}}> WE DO NOT ACCEPT OR TRADE IN STOLEN PHONES!</h4>
              </div>
              </div>              
            </div>
            <div className='col-sm-5'> 
              <div style={{padding:20, borderWidth:1, borderColor:'#ddd',width:'95%',  justifyContent:'center'}}> 
              <section id="Contactus">
              <h4 style={{fontWeight:400, color:'#fff'}}> CONTACT US </h4>
              <hr style={{borderColor:'#fff', borderStyle:'solid'}}/>  
              <div>
                <div style={{color:'#fff', fontWeight:600}}> 
                <h5 style={{fontWeight:600,fontStyle:'bold'}}> Sales Hotline: </h5>
                <div style={{fontWeight:600,fontStyle:'bold',fontSize:22}}> Call: 0903 913 7405 | Chat: 0803 331 5354 </div>
                <h5 style={{fontWeight:600,fontStyle:'bold'}}>  </h5>
                </div>
                <hr style={{borderColor:'#fff', borderStyle:'solid'}}/>               
                <div style={{color:'#fff', fontWeight:600}}> 
                Email: hello@Nijatech.ng
                </div>
                <hr style={{borderColor:'#fff', borderStyle:'solid'}}/>
                <div style={{color:'#fff', fontWeight:600}}> 
                    Office Address: 1, Otigba Street (3rd floor), beside Fidelity Bank, Computer Village (Opposite Awolowo House), Ikeja, Lagos. 
                </div>
                <hr style={{borderColor:'#fff', borderStyle:'solid'}}/>
                <div style={{color:'#fff', fontWeight:600}}> 
                    For Complaints: +234 (0)802 654 4943
                </div>              
              </div>   
              </section>  
              </div>                
            </div>  
            </div>
            <div className='row' style={{backgroundColor:'#041E42',textAlign:'center', padding:10, marginTop:2}}>
            <div className='col-sm-12'>
                <h6 style={{color:'#fff'}}> Nijatech Nigeria Limited &copy; 2023. All rights reserved</h6>
                </div>
            </div>   
        </div> 
       
            </div>
    )
  }
}
