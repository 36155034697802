import React, { Component } from 'react'
import AboutBody from './AboutBody'

import Navigation from './NavigationControl';
import { Outlet } from 'react-router-dom';
export default class About extends Component {
  render() {
    return (
        <div style={{padding:0, margin:0, backgroundColor:'#0a2351', display: 'flex',flexDirection: 'column',minHeight: '100vh'}}>
        <AboutBody />
        <Outlet />
        </div>
    )
  }
}
